
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Campos Dinámicos</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridCamposDinamicos"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','campos_dinamicos_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>

    <Modal v-if="openModalForm" :options="{width: '70vw',type:'info'}" @close="cancelar_seleccion">
      <div class="title">{{ !itemSeleccionado.id ? 'Agregar Campo' : 'Editar Campo'}}</div>
      <div class="body">
        <div class="row justify-content-center mt-4">
          <div class="col-sm-4 form-group">
            <button type="button" class="btn btn-link btn-sm text-info" data-toggle="popover" title="Este nombre aparecerá en los formularios"><i class="fa fa-question-circle"></i></button>

            <label for="nombre" class="form-label font-weight-bold">Nombre</label>
            <input v-model="itemSeleccionado.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-sm-4 form-group">
            <label for="nombre" class="form-label font-weight-bold">Clave</label>
            <input v-model="itemSeleccionado.clave" type="text" name="clave" id="clave" class="form-control">
          </div>

          <div class="col-sm-4 form-group">
            <label for="tipo" class="form-label font-weight-bold">Tipo</label>
            <select class="form-control" name="tipo" id="tipo"  v-model="itemSeleccionado.tipo" :disabled="editarCampo">
              <option  v-for="tipo in tipos_campos" :value="tipo">{{tipo}}</option>
            </select>
          </div>

          <div class="col-sm-4 form-group">
            <label for="activo" class="form-label font-weight-bold">Estatus</label>
            <select class="form-control" name="activo" id="activo"  v-model="itemSeleccionado.activo">
              <option :value="1">Activo</option>
              <option :value="0">Inactivo</option>
            </select>
          </div>

          <div class="col-sm-4 form-group">
            <button type="button" class="btn btn-link btn-sm text-info" data-toggle="popover" title="Es el orden en que el campo aparecerá en los formularios"><i class="fa fa-question-circle"></i></button>
            <label for="orden" class="form-label font-weight-bold">Orden del campo</label>
            <input v-model="itemSeleccionado.orden" type="number" name="orden" id="orden" class="form-control">
          </div>


          <div class="col-sm-4 form-group">
            <label for="tipo_dato" class="form-label font-weight-bold">Tipo de dato</label>
            <select class="form-control" name="tipo_dato" id="tipo_dato"  v-model="itemSeleccionado.tipo_dato">
              <option  v-for="tipo in tipos_datos_campos" :value="tipo.tipo">{{tipo.nombre}}</option>
            </select>
          </div>



          <template v-if="itemSeleccionado.tipo_dato === 'array'">
            <div class="col-sm-4 form-group">
              <label for="campo_ligado_a_catalogo" class="form-label font-weight-bold">¿El campo estará ligado a un catálogo?</label>
              <select class="form-control" name="campo_ligado_a_catalogo" id="campo_ligado_a_catalogo"  v-model="campo_ligado_a_catalogo" @change="cambioCampoCatalogo">
                <option :value="1">Si</option>
                <option :value="0">No</option>
              </select>
            </div>

            <div  v-show="campo_ligado_a_catalogo == 1" class="col-sm-4 form-group">
              <label for="catalogo_id" class="form-label font-weight-bold">Catálogo de campo</label>
              <select class="form-control" name="catalogo_id" id="catalogo_id"  v-model="itemSeleccionado.catalogo_id">
                <option v-for="catalogo in catalogos" :value="catalogo.id">{{catalogo.nombre}}</option>
              </select>
            </div>

            <div v-show="campo_ligado_a_catalogo == 0" class="col-sm-8 form-group">
              <label for="valores" class="form-label font-weight-bold mr-sm-1">Opciones</label>
              <small class="text-primary">* Importante: las opciones deben estar separadas por comas, ejemplo: opc 1,opc 2,etc..</small>
              <input v-model="itemSeleccionado.valores" type="text" name="valores" id="valores" class="form-control">
            </div>

          </template>

        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar"><i class="fa fa-check-square mr-sm-1"></i>{{ !itemSeleccionado.id ? 'Guardar' : 'Guardar Cambios'}}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar campo {{ itemSeleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Si elimina el campo de <b> {{ itemSeleccionado.nombre }}</b>  y existen registros de <b>{{itemSeleccionado.tipo.toUpperCase()}}</b> asociadas al campo también serán eliminados y podrían no recuperarse.
            <br>
            ¿Estas seguro que deseas continuar con la eliminación del campo?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import Cliente from "@/apps/garantias_app/pages/clientes/Cliente.vue";
import campos_dinamicosApi from "@/apps/garantias_app/api/campos_dinamicos/campos_dinamicos.api";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import fondeadoresApi from "@/apps/garantias_app/api/fondeadores/fondeadores.api";

export default {
  name: "CamposDinamicos",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false
      ,tipos_datos_campos:[
        {tipo: 'string', nombre: 'Texto'},
        {tipo: 'date', nombre: 'Fecha'},
        {tipo: 'array', nombre: 'Opcion Múltiple'},
        {tipo: 'porcentaje', nombre: 'Porcentaje'},
        {tipo: 'money', nombre: 'Moneda'},
      ]
      ,tipos_campos:[
        'fideicomiso','credito','persona', 'garantia-historico'
      ]
      ,catalogos:[]
      ,dataGridConfig: {
        name: 'garantias_campos_dinamicos',
        cols: {
          acciones: '',
          nombre: 'Nombre',
          tipo: 'Tipo',
          orden: 'Orden',
          activo: 'Estatus',
        }
        ,filters: {
          cols: {
            nombre: 'Nombre',
            tipo: 'Tipo',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','campos_dinamicos_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-acciones btn-sm btn-primary fa fa-edit mr-sm-2'></i>";

            return acciones+"</div>";
          },
          activo: function (val){
            return !val ? 'Inactivo' : 'Activo'
          }
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        tipo: null,
        tipo_dato: null,
        clave: null,
        nombre: null,
        activo: null,
        valores: null,
        catalogo_id: null,
        orden: null,
      }
      ,campo_ligado_a_catalogo: 0
    }

  },
  components: {
    FormularioCliente,
    Cliente,
    DataGridV3,
    Modal
  },
  computed: {

    validaciones(){
      let validaciones_catalogo = {};

      if (this.itemSeleccionado.tipo_dato === 'array')
        validaciones_catalogo = {
          campo_ligado_a_catalogo: this.campo_ligado_a_catalogo === null ? 'required' : '',
          valores: this.campo_ligado_a_catalogo === 0  ? 'required|max:255' : '',
          catalogo_id:  (this.campo_ligado_a_catalogo === 1 && !this.itemSeleccionado.catalogo_id) ?  'required' : '',
        }

      return {
        ...validaciones_catalogo,
        tipo: !this.itemSeleccionado.tipo  ? 'required' : 'max:255',
        tipo_dato: !this.itemSeleccionado.tipo_dato  ? 'required' : 'max:255',
        clave: !this.itemSeleccionado.clave  ? 'required' : 'max:50',
        nombre: !this.itemSeleccionado.nombre  ? 'required' : 'max:255',
        activo: this.itemSeleccionado.activo === null ? 'required' : '',
        orden: !this.itemSeleccionado.orden  ? 'required' : '',
      }

    },
    editarCampo() {
      return typeof this.itemSeleccionado.id === 'string'
    }
  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,cancelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

     this.itemSeleccionado = {
        tipo: null,
        tipo_dato: null,
        clave: null,
        nombre: null,
        activo: null,
        valores: null,
        catalogo_id: null,
        orden: null,
      };
    },

    cambioCampoCatalogo(){
      //Limpia valores de inputs catalogo id y valores al cambiar de opción de campo ligado a catálogo
      this.itemSeleccionado.catalogo_id = null
      this.itemSeleccionado.valores = null
    },

    async crear(){
      await this.cargar_catalogos();
      this.openModalForm = true;
    },

    async cargar_catalogos(){
      if (!this.catalogos.length) {
        const dataSource = await catalogosApi.index(false)
        this.catalogos = dataSource.data
      }
    },

    async editar(){

      await this.cargar_catalogos()

      if (this.editarCampo) {
        this.campo_ligado_a_catalogo = !this.itemSeleccionado.catalogo_id ? 0 : 1
      }

      this.openModalForm = true;
    },
    pre_eliminar(){
      this.openModalEliminar = true;

    },

    async eliminar(){
      this.openModalEliminar = false;
      await campos_dinamicosApi.eliminar(this.itemSeleccionado.id).then(() => {
        this.$helper.showMessage('Eliminar campo','Campo eliminado exitosamente.', 'success', 'alert')
        this.cargar()
        this.cancelar_seleccion()
      })
    },

    async cargar() {
      let dataSource = await campos_dinamicosApi.index({}, false)
      this.dataSourceGrid = dataSource.data
    },

    async guardar(){
      let isValid = await this.$validator(this.validaciones)

      if (isValid) {
        try {

          const payload = {...this.itemSeleccionado, campo_ligado_a_catalogo: this.campo_ligado_a_catalogo}

          if (this.editarCampo) {
            const request = await campos_dinamicosApi.editar(this.itemSeleccionado.id,  payload)
            this.$helper.showMessage('Editar campo', 'Información guardada exitosamente.', 'success', 'alert')

          }else{
            const request = await campos_dinamicosApi.crear(payload)
            this.$helper.showMessage('Crear campo', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await  this.cargar()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.cliente_id ? 'Guardar' : 'Editar')+ ' campo', 'Ocurrio un error al guardar datos del campo, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    }
  }

  ,watch: {
    'itemSeleccionado.tipo_dato': {
      handler(newValue, oldValue) {
        //Si el tipo de dato cambia de array a otro, se resetean valores de: campo_ligado_a_catalogo, catalogo_id y valores
        if (newValue !== 'array') {
          this.campo_ligado_a_catalogo = 0
          this.itemSeleccionado.catalogo_id = null
          this.itemSeleccionado.valores = null
        }
      }
    }
  }

}
</script>


<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>